import { useEffect } from "react";
import { useRouter } from "next/router";

const pageview = (url) => {
  console.log("Send pageview", document.title, url);
  window.dataLayer.push({
    event: "Pageview",
    pagePath: url,
    pageTitle: document.title,
  });
  // window.gtag("event", "page_view", { page_path: url });
};

export { pageview };

const usePageviews = () => {
  const router = useRouter();

  useEffect(() => {
    // Initial pageview
    pageview(router.asPath);

    const handleRouteChange = (url, { shallow }) => {
      if (!shallow) pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

export default usePageviews;
