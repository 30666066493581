import Cookies from "universal-cookie";
import fetch from "isomorphic-unfetch";

async function fetchUser(ctx = null) {
  const cookies = new Cookies(ctx ? ctx.req.headers.cookie : null);

  // Check cookie
  let user = cookies.get("userObj");
  if (user) return user;

  // No cookie, API request
  const response = await fetch(`${process.env.NEXT_PUBLIC_API}/user`);
  user = await response.json();

  // Store user to cookie
  cookies.set("userObj", user, { path: "/", maxAge: 30 * 24 * 60 * 60 });

  return user;
}

export default fetchUser;
