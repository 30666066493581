import React, { useContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import fetchUser from "./fetchUser";

const UserContext = React.createContext();

const cookies = new Cookies();

const Provider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [converted, setConverted] = useState(!!cookies.get("amazonConverted"));

  useEffect(() => {
    if (converted) {
      cookies.set("amazonConverted", true, { path: "/", maxAge: 60 * 60 });
    } else {
      cookies.remove("amazonConverted");
    }
  }, [converted]);

  useEffect(() => {
    const fetchData = async () => {
      const user = await fetchUser();
      setUser(user);
    };

    fetchData();
  }, []);

  const updateUser = (userObj) => {
    setUser(userObj);

    // Update user cookie object
    cookies.set("userObj", userObj, {
      path: "/",
      maxAge: 30 * 24 * 60 * 60,
    });
  };

  return (
    <UserContext.Provider value={{ user, updateUser, converted, setConverted }}>
      {children}
    </UserContext.Provider>
  );
};
Provider.displayName = "UserProvider";

export const Consumer = UserContext.Consumer;

export const useUser = () => {
  const { user } = useContext(UserContext);

  return user;
};

export const useUpdateUser = () => {
  const { updateUser } = useContext(UserContext);

  return updateUser;
};

export const useConverted = () => {
  const { converted, setConverted } = useContext(UserContext);
  return [converted, setConverted];
};

export default Provider;
