import NProgress from "nprogress";
import Router from "next/router";

NProgress.configure({ showSpinner: false, trickleSpeed: 100 });

const startProgress = () => NProgress.start();

const stopProgress = (timer) => {
  clearTimeout(timer);
  NProgress.done();
};

const showProgressBar = (delay) => {
  const timer = setTimeout(startProgress, delay);
  Router.events.on("routeChangeComplete", () => stopProgress(timer));
  Router.events.on("routeChangeError", () => stopProgress(timer));
};

Router.events.on("routeChangeStart", () => showProgressBar(100));
