const theme = {
  palette: {
    main: "#363334",
    primary: "#8bc220",
    secondary: "#560077",
    pink: "#d83386",
    link: "#23527c",
    border: "#e7e7e7",
  },
};

export default theme;
