import React from "react";
import get from "lodash/get";
import { ThemeProvider } from "styled-components";
import theme from "lib/theme";
import "lib/nprogress";
import { setUTMOverride } from "lib/overrides/utmOverride";
import usePageviews from "lib/gtm";
import UserProvider from "lib/user/context";
import BugSnag from "components/BugSnag";
import MetaTags from "components/MetaTags";

import "styles/index.scss";

function MyApp({ Component, pageProps, router }) {
  // Check/Set UTM Override
  setUTMOverride(get(router, "query.utm_content", null));

  // GTM handle pageviews
  usePageviews();

  return (
    <BugSnag>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <MetaTags />

          <Component {...pageProps} />
        </ThemeProvider>
      </UserProvider>
    </BugSnag>
  );
}

export default MyApp;
