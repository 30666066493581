import Cookies from "universal-cookie";
import get from "lodash/get";
import { db } from "lib/firebase";

const cookies = new Cookies();

export const setUTMOverride = (utm_tag) => {
  if (utm_tag) {
    cookies.set("tag", utm_tag, { path: "/", maxAge: 1 * 24 * 60 * 60 });
  }
};

export const checkUTMOverride = async (quiz, country) => {
  // Check user defined tag from ?utm_content=[tag]
  let tag = await checkUserTagOverride(country);
  if (tag) return tag;

  // Check quiz related tag overrides
  tag = get(quiz.overrides, country, null);
  return tag;
};

// Check user defined tag from ?utm_content=[tag]
async function checkUserTagOverride(country) {
  const tag = cookies.get("tag");

  if (!tag) return null;

  // Query to check if user tag matches global list
  const query = await db
    .collection("utm-overrides")
    .where("text", "==", tag.toLowerCase())
    .where("country", "==", country)
    .limit(1)
    .get();

  if (query.empty) return null;

  const doc = query.docs[0];
  const data = doc.data();

  return data ? data.tag : null;
}
