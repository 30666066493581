/**
 * Initialise Firebase
 */

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

let config = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
};

if (!firebase.apps.length) firebase.initializeApp(config);

firebase.firestore.setLogLevel("error");

export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

let CLOUD_URL = `https://us-central1-${config.projectId}.cloudfunctions.net`;

// If you want to use a local functions emulator, uncomment this
// if (process.env.NODE_ENV === "development") {
//   functions.useFunctionsEmulator("http://localhost:5000");
//   CLOUD_URL = `http://localhost:5000/${config.projectId}/us-central1`;
// }

export { CLOUD_URL };

export default firebase;
