import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
    appVersion: "10.0.0",
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
  });
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const BugSnagComponent = ({ children }) => {
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default BugSnagComponent;
